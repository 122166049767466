export const ALL_PCTCODE_REQUEST = "ALL_PCTCODE_REQUEST"
export const ALL_PCTCODE_SUCCESS = "ALL_PCTCODE_SUCCESS"
export const ALL_PCTCODE_FAIL = "ALL_PCTCODE_FAIL"

export const ALL_PCTCODE_POST_REQUEST = "ALL_PCTCODE_POST_REQUEST"
export const ALL_PCTCODE_POST_SUCCESS = "ALL_PCTCODE_POST_SUCCESS"
export const ALL_PCTCODE_POST_FAIL = "ALL_PCTCODE_POST_FAIL"

export const ALL_PCTCODE_UPDATE_REQUEST = "ALL_PCTCODE_UPDATE_REQUEST"
export const ALL_PCTCODE_UPDATE_SUCCESS = "ALL_PCTCODE_UPDATE_SUCCESS"
export const ALL_PCTCODE_UPDATE_FAIL = "ALL_PCTCODE_UPDATE_FAIL"

export const ALL_PCTCODE_DETAILS_REQUEST = "ALL_PCTCODE_DETAILS_REQUEST"
export const ALL_PCTCODE_DETAILS_SUCCESS = "ALL_PCTCODE_DETAILS_SUCCESS"
export const ALL_PCTCODE_DETAILS_FAIL = "ALL_PCTCODE_DETAILS_FAIL"

export const ALL_DISTINCT_COMPANIES_FROM_PCTCODE_REQUEST = "ALL_DISTINCT_COMPANIES_FROM_PCTCODE_REQUEST"
export const ALL_DISTINCT_COMPANIES_FROM_PCTCODE_SUCCESS = "ALL_DISTINCT_COMPANIES_FROM_PCTCODE_SUCCESS"
export const ALL_DISTINCT_COMPANIES_FROM_PCTCODE_FAIL = "ALL_DISTINCT_COMPANIES_FROM_PCTCODE_FAIL"

export const ALL_PCTCODE_DETAILS_ONCOMPANYANDTYPE_REQUEST = "ALL_PCTCODE_DETAILS_ONCOMPANYANDTYPE_REQUEST"
export const ALL_PCTCODE_DETAILS_ONCOMPANYANDTYPE_SUCCESS = "ALL_PCTCODE_DETAILS_ONCOMPANYANDTYPE_SUCCESS"
export const ALL_PCTCODE_DETAILS_ONCOMPANYANDTYPE_FAIL = "ALL_PCTCODE_DETAILS_ONCOMPANYANDTYPE_FAIL"

export const ALL_PCTCODE_DELETE_REQUEST = "ALL_PCTCODE_DELETE_REQUEST"
export const ALL_PCTCODE_DELETE_SUCCESS = "ALL_PCTCODE_DELETE_SUCCESS"
export const ALL_PCTCODE_DELETE_FAIL = "ALL_PCTCODE_DELETE_FAIL"

export const ALL_PCTCODE_COMPANY_REQUEST = "ALL_PCTCODE_COMPANY_REQUEST"
export const ALL_PCTCODE_COMPANY_SUCCESS = "ALL_PCTCODE_COMPANY_SUCCESS"
export const ALL_PCTCODE_COMPANY_FAIL = "ALL_PCTCODE_COMPANY_FAIL"

export const ALL_PCTCODE_COMPANY_POST_REQUEST = "ALL_PCTCODE_COMPANY_POST_REQUEST"
export const ALL_PCTCODE_COMPANY_POST_SUCCESS = "ALL_PCTCODE_COMPANY_POST_SUCCESS"
export const ALL_PCTCODE_COMPANY_POST_FAIL = "ALL_PCTCODE_COMPANY_POST_FAIL"

export const ALL_PCTCODE_COMPANY_UPDATE_REQUEST = "ALL_PCTCODE_COMPANY_UPDATE_REQUEST"
export const ALL_PCTCODE_COMPANY_UPDATE_SUCCESS = "ALL_PCTCODE_COMPANY_UPDATE_SUCCESS"
export const ALL_PCTCODE_COMPANY_UPDATE_FAIL = "ALL_PCTCODE_COMPANY_UPDATE_FAIL"

export const ALL_PCTCODE_COMPANY_DETAILS_REQUEST = "ALL_PCTCODE_COMPANY_DETAILS_REQUEST"
export const ALL_PCTCODE_COMPANY_DETAILS_SUCCESS = "ALL_PCTCODE_COMPANY_DETAILS_SUCCESS"
export const ALL_PCTCODE_COMPANY_DETAILS_FAIL = "ALL_PCTCODE_COMPANY_DETAILS_FAIL"

export const ALL_PCTCODE_DESCRIPTION_REQUEST = "ALL_PCTCODE_DESCRIPTION_REQUEST"
export const ALL_PCTCODE_DESCRIPTION_SUCCESS = "ALL_PCTCODE_DESCRIPTION_SUCCESS"
export const ALL_PCTCODE_DESCRIPTION_FAIL = "ALL_PCTCODE_DESCRIPTION_FAIL"

export const ALL_PCTCODE_DESCRIPTION_POST_REQUEST = "ALL_PCTCODE_DESCRIPTION_POST_REQUEST"
export const ALL_PCTCODE_DESCRIPTION_POST_SUCCESS = "ALL_PCTCODE_DESCRIPTION_POST_SUCCESS"
export const ALL_PCTCODE_DESCRIPTION_POST_FAIL = "ALL_PCTCODE_DESCRIPTION_POST_FAIL"

export const ALL_PCTCODE_DESCRIPTION_UPDATE_REQUEST = "ALL_PCTCODE_DESCRIPTION_UPDATE_REQUEST"
export const ALL_PCTCODE_DESCRIPTION_UPDATE_SUCCESS = "ALL_PCTCODE_DESCRIPTION_UPDATE_SUCCESS"
export const ALL_PCTCODE_DESCRIPTION_UPDATE_FAIL = "ALL_PCTCODE_DESCRIPTION_UPDATE_FAIL"

export const ALL_PCTCODE_DESCRIPTION_DETAILS_REQUEST = "ALL_PCTCODE_DESCRIPTION_DETAILS_REQUEST"
export const ALL_PCTCODE_DESCRIPTION_DETAILS_SUCCESS = "ALL_PCTCODE_DESCRIPTION_DETAILS_SUCCESS"
export const ALL_PCTCODE_DESCRIPTION_DETAILS_FAIL = "ALL_PCTCODE_DESCRIPTION_DETAILS_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"
